/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // JavaScript to be fired on all pages
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        // JavaScript to be fired on the home page
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

  $('.main-nav ul li').hover(function(){
      $(this).find('ul:first').css({visibility: "visible",display: "none"}).slideDown(400);
      },function(){
      $(this).find('ul:first').css({visibility: "hidden",display: "none"});
  });

  $("nav#menu").append($("nav.main-nav").html());

  $(".icon-search").on("click", function(e) {
    $('.search-block').toggleClass("is-visible");
    e.preventDefault();
  });

  $(".page-down > a").click(function() {
      $('html, body').animate({
          scrollTop: $(".wrap").offset().top
      }, 'slow');
  });

  $("#slideshow").slick({
    infinite: true,
    speed: 350,
    rows: 0,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    autoplay: true,
    arrows:true,
    pauseOnHover: false,
    prevArrow:'<a heref="#" class="slider-nav fa fa-arrow-left prev-arrow"></a>',
    nextArrow:'<a heref="#" class="slider-nav fa fa-arrow-right next-arrow"></a>',
    responsive: [
      {
        breakpoint: 600,
        settings: {
            arrows:false
          }
      }
    ]
  });

  $('#feature_event').slick({
      infinite: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      rows: 0,
      centerMode: true,
      arrows:false,
      prevArrow:'<a heref="#" class="slider-nav fa fa-arrow-left prev-arrow"></a>',
      nextArrow:'<a heref="#" class="slider-nav fa fa-arrow-right next-arrow"></a>',
      dots: false,
      responsive: [
        {
          breakpoint: 9999,
          settings: "unslick"
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            arrows:true
          }
        }
      ]
    });
    
  $("#logo_slider").slick({
    infinite: true,
    speed: 300,
    rows: 0,
    arrows:false,
    autoplay: true,
    dots: true,
    customPaging: function(slider, i) {
      return $('<a>').text(i + 1);
    },
    slidesToShow: 10,
    slidesToScroll:1,
    responsive: [
      
      {
        breakpoint:1024,
        settings: {
          slidesToShow: 6,
          slidesToScroll:3
        }
      },
      {
        breakpoint:960,
        settings: {
          slidesToShow: 4,
          slidesToScroll:4
        }
      },       
      {

        breakpoint: 600,
        settings: {
          slidesToShow: 3,
          slidesToScroll:3
        }
      }, 
      {
        breakpoint: 400,
        settings: {
          slidesToShow: 3,
          slidesToScroll:3
        }
      }
    ]
  });
 
})(jQuery); // Fully reference jQuery after this point.
